<template>
  <div class="trusteeship-child">
    <div class="scroll-content">
      <img class="banner-img" src="./img/sqty_banner.png" alt="社区养老" />
      <div class="yuyue-content">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          :immediate-check="false"
          @load="getList"
        >
          <ul class="yuyue-list">
            <li
              class="yuyue-item"
              v-for="(item, index) in yuyueList"
              :key="'wdyy' + index"
              @click="yuyuItemOnClick(item, index)"
            >
              <div class="header-text">
                <div class="value-text">姓名：{{ item.olderName || "-" }}</div>
                <div class="value-text age-text">
                  出生年月：{{ item.olderBirthday || "-" }}
                </div>
              </div>
              <div class="content-text">
                <div class="value-text">
                  <span class="label-text">照护周期：</span>
                  {{ item.nurseTime }}
                </div>
                <div class="value-text">
                  <span class="label-text">状态：</span>
                  <span
                    :class="[
                      'tag',
                      {
                        dsh: item.auditStatus === EXAMINE_WAIT,
                        shtg: item.auditStatus === EXAMINE_PASS,
                        shwtg: item.auditStatus === EXAMINE_REFUSE,
                      },
                    ]"
                  >
                    {{ examineStatusMap[item.auditStatus] || "-" }}
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </van-list>
      </div>
    </div>
    <div class="footer-handle">
      <div class="wyyy-btn" @click="appointOnClick">我要预约</div>
    </div>
  </div>
</template>

<script>
import {
  EXAMINE_WAIT,
  EXAMINE_PASS,
  EXAMINE_REFUSE,
  examineStatusMap,
} from "./map.js";
import { getTyyyListUrl } from "./api.js";
import { mapState } from "vuex";
import { List } from "vant";

export default {
  name: "trusteeshipChild",
  components: {
    [List.name]: List,
  },
  props: {},
  model: {},
  data() {
    return {
      EXAMINE_WAIT,
      EXAMINE_PASS,
      EXAMINE_REFUSE,
      examineStatusMap,
      loading: false,
      finished: false,
      error: false,
      total: 0,
      params: {
        curPage: 1,
        pageSize: 10,
        userId: "",
      },
      yuyueList: [],
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  watch: {},
  created() {
    this.params.userId = this.userId;
    this.getList();
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    getList() {
      this.$axios
        .get(getTyyyListUrl, { params: this.params })
        .then((res) => {
          if (res && res.code === 200) {
            res.data = res.data || {};
            res.data.records = Array.isArray(res.data.records)
              ? res.data.records
              : [];
            this.yuyueList.push(...res.data.records);
            this.params.curPage++;
            this.total = res.data.total;
            this.loading = false;
            if (this.yuyueList.length >= this.total) {
              this.finished = true;
            }
          } else {
            this.loading = false;
            this.error = true;
          }
        })
        .catch(() => {
          this.loading = false;
          this.error = true;
        });
    },
    yuyuItemOnClick(value, index) {
      if ([EXAMINE_PASS].includes(value.auditStatus)) return;
      this.$router.push({
        name: "trusteeshipChildAddEdit",
        query: { id: value.id },
      });
    },
    appointOnClick() {
      this.$router.push({
        name: "trusteeshipChildAddEdit",
      });
    },
  },
};
</script>

<style lang="less" scoped>
// constant(safe-area-inset-*)
// env(safe-area-inset-*)
// safe-area-inset-top
// safe-area-inset-right
// safe-area-inset-bottom
// safe-area-inset-left
@height: 128px;

.trusteeship-child {
  box-sizing: border-box;
  height: 100vh;
  background-color: #f9f9f9;
  padding-bottom: 0px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
.scroll-content {
  box-sizing: border-box;
  padding: 20px;
  max-height: calc(100% - @height);
  overflow-x: hidden;
  overflow-y: auto;
}
.banner-img {
  box-sizing: border-box;
  width: 100%;
  display: block;
}
.yuyue-list {
  box-sizing: border-box;
  .yuyue-item {
    box-sizing: border-box;
    margin-top: 20px;
    background-color: #ffffff;
    box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
    border-radius: 16px;
    padding: 10px 20px;
    .header-text {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      border-bottom: 1px solid #f9f9f9;
      padding: 10px;
      .value-text {
        box-sizing: border-box;
        font-size: 32px;
        color: #1a1c34;
        font-weight: 700;
        &.age-text {
          font-size: 24px;
          color: #efc06c;
          font-weight: 400;
        }
      }
    }
    .content-text {
      box-sizing: border-box;
      padding: 5px 10px;
      .value-text {
        box-sizing: border-box;
        font-size: 26px;
        color: rgba(0, 0, 0, 0.85);
        display: flex;
        align-items: center;
        line-height: 46px;
        margin: 15px 0px;
        .label-text {
          box-sizing: border-box;
          flex-shrink: 0;
          width: 140px;
        }
        .tag {
          box-sizing: border-box;
          height: 46px;
          font-size: 24px;
          padding: 0px 16px;
          border-radius: 8px;
          &.dsh {
            background-color: #fff0e7;
            color: #f27f3b;
          }
          &.shtg {
            background-color: #e3ffec;
            color: #51c475;
          }
          &.shwtg {
            background-color: #ffe7e7;
            color: #efc06c;
          }
        }
      }
    }
  }
}
.footer-handle {
  box-sizing: border-box;
  width: 100vw;
  height: @height;
  padding: 20px;
  position: fixed;
  left: 0px;
  bottom: 0px;
  .wyyy-btn {
    box-sizing: border-box;
    width: 100%;
    height: 88px;
    border-radius: 16px;
    background-color: #efc06c;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
  }
}
</style>
